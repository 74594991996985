import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {
    Button,
    Container,
    Icon,
} from 'semantic-ui-react'
import {Input} from 'semantic-ui-react'
import './App.css'
import {Form, TextArea} from 'semantic-ui-react'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {bootstrap: '', total: null, startPort: null, commands: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });
    }


    handleClick = (ev) => {
        const command = 'docker run -p %p:%p -d iranmanesh/opendht dhtnode -b %s -p %p -s &'
        let startPort = parseInt(this.state.startPort);
        let wholeStr = ''
        for (let i = 0; i < this.state.total; i++) {

            wholeStr += command.replace('%s', this.state.bootstrap).replace(/%p/g, startPort).concat('\n')
            startPort += 1
        }
        this.setState({commands: wholeStr})

    }


    render() {

        return (
            <div className='container'
                 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <img src="/logo.svg" style={{width: 300, margin: 50}} alt=""/>

                <Input value={this.state.total} onChange={this.handleChange} label='Number of nodes' name='total'
                       placeholder='0'/>
                <Input value={this.state.startPort} onChange={this.handleChange} label='start port number'
                       name='startPort' placeholder='0'/>
                <Input value={this.state.bootstrap} onChange={this.handleChange} label='Bootstrap Node IP:Port'
                       name='bootstrap' placeholder='iranmanesh.me:4222'/>
                <Button primary size='medium' onClick={this.handleClick} style={{margin: 20}}>
                    Generate Commands
                    <Icon name='right arrow'/>
                </Button>
                <TextArea style={{'minHeight': '700px', minWidth: '700px'}} value={this.state.commands}
                          placeholder='Commands'/>
            </div>
        )
    }
}


export default App
